.book-list-section {
    background-color: #ffe6c0;
    position: relative;
    padding: 50px 0 300px;
    min-height: 100vh;
    .inner {
        padding-top: 60px;
    }
    .library-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 16px;
        border-bottom: 1px solid var(--bs-green-primary);
        margin-bottom: 30px;
        h3 {
            font-size: 1.7rem;
            font-weight: 700;
            color: var(--bs-green-primary);
            font-family: 'Maple';
        }
        h6 {
            padding: 0 0 0 16px;
            font-size: 1rem;
            font-weight: 500;
            font-family: 'Maple';
        }
        .make-story-btn {
            padding: 1.25rem 3.5rem;
            background-color: var(--bs-green-primary);
            border-radius: 4px;
            color: var(--bs-white);
            font-size: 24px;
            transition-duration: 0.3s;
            cursor: pointer;
            font-family: 'Maple';
            display: flex;
            align-items: center;
            gap: 0.4rem;
            figure {
                width: 30px;
            }
            &:hover {
                background-color: var(--bs-green-light);
                cursor: pointer;
            }
        }
    }
    .book-list {
        display: flex;
        flex-wrap: wrap;
        gap: 26px;
    }
    .book-bottom {
        position: absolute;
        bottom: 0;
    }
    // 2024-02-14 | footer-text 추가
    .footer-txt {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: gray;
        padding-bottom: 0.5rem;
        font-weight: bold;
        .site {
            cursor: pointer;
        }
    }
}
// mediaQuery
@media screen and (max-width: 1440px) {
    .book-list-section {
        .inner {
            width: 90%;
        }
        .book-list {
            justify-content: space-between;
        }
        .book-list::after {
            content: '';
            width: 300px;
            height: 0;
        }
    }
}
