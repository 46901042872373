.rechoice-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    position: absolute;
    z-index: 999;
    .inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.5rem;
        // 2024-02-13 | position 추가
        position: relative;
        // 2024-02-13 | 닫기버튼 추가 css
        .close-modal-btn {
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffc377;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            padding: 0.8rem;
            position: absolute;
            right: 20px;
            top: 50px;
        }
        .re-btn {
            width: 460px;
            height: 104px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            cursor: pointer;
            transition-duration: 0.3s;
        }
        .from-beginning-btn {
            background-color: var(--bs-green-primary);
            color: var(--bs-white);
            font-family: 'Maple';
            font-size: 42px;
            &:hover {
                background-color: var(--bs-green-light);
            }
        }
        .my-library-btn {
            background-color: #ddd;
            font-family: 'Maple';
            font-size: 42px;
            &:hover {
                background-color: #fff;
            }
        }
    }
}
// tab-미디어쿼리 (임시)
@media (min-width: 600px) and (max-width: 1200px) {
    .rechoice-section {
        .inner {
            .re-btn {
                width: 600px;
                height: 140px;
                font-size: 40px;
            }
        }
    }
}