.floating-btn {
    position: fixed;
    bottom: -24px;
    right: -10px;
    z-index: 999;
    .floating-container {
        position: relative;
        .bubble {
            width: 300px;
            position: relative;
            bottom: 187px;
            right: 20px;
            z-index: 100;
        }
        .char {
            position: absolute;
            width: 330px;
            bottom: -88px;
            right: -83px;
            transform: rotate(359deg);
            z-index: 99;
            cursor: pointer;
        }
    }
    .back-magickid {
        width: 460px;
        padding: 1rem;
        position: absolute;
        bottom: 0;
        right: -20px;
        cursor: pointer;
    }
}
// mediaQuery
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .floating-btn {
        .floating-container {
            .bubble {
                width: 260px;
                bottom: 148px;
                right: 13px;
            }
            .char {
                width: 280px;
                bottom: -89px;
                right: -76px;
            }
        }
        .back-magickid {
            bottom: -1px;
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .floating-btn {
        .floating-container {
            .bubble {
                width: 210px;
                bottom: 132px;
                right: 27px;
            }
            .char {
                width: 220px;
                bottom: -50px;
                right: -47px;
            }
        }
        .back-magickid {
            width: 370px;
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .floating-btn {
        .floating-container {
            .bubble {
                width: 210px;
                bottom: 140px;
                right: 13px;
            }
            .char {
                width: 220px;
                bottom: -50px;
                right: -47px;
            }
        }
        .back-magickid {
            width: 370px;
            right: 0;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .floating-btn {
        .floating-container {
            .bubble {
                width: 210px;
                bottom: 134px;
                right: 13px;
            }
            .char {
                width: 220px;
                bottom: -50px;
                right: -57px;
            }
        }
        .back-magickid {
            width: 370px;
        }
    }
}
// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 767px) {
    .floating-btn {
        .floating-container {
            .bubble {
                width: 210px;
                bottom: 140px;
                right: 17px;
            }
            .char {
                width: 220px;
                bottom: -50px;
                right: -57px;
            }
        }
        .back-magickid {
            width: 370px;
            right: 0;
        }
    }
}