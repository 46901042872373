.backward-modal {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        figure {
            width: 45%;
            animation: move-up 0.8s 1s infinite alternate;
        }
        .backward-txt-container {
            background-color: var(--bs-white);
            padding: 2rem;
            border-radius: 4px;
            p {
                font-size: 36px;
                font-family: 'Maple';
                em {
                    color: var(--bs-green-light);
                }
            }
            .btn-container {
                display: flex;
                justify-content: center;
                gap: 1rem;
                margin-top: 2rem;
                button {
                    border: none;
                    cursor: pointer;
                    font-family: 'Maple';
                    padding: 1rem 2rem;
                    border-radius: 4px;
                    font-size: 20px;
                    &.go-back {
                        background-color: #86e3ce;
                    }
                    &.on-stay {
                        background-color: #d0e6a5;
                    }
                }
            }
        }
    }
    @keyframes move-up {
        100% {
            transform: translateY(10px);
        }
    }
}
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .backward-modal {
        .inner {
            figure {
                width: 35%;
            }
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .backward-modal {
        .inner {
            figure {
                width: 35%;
            }
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .backward-modal {
        .inner {
            figure {
                width: 35%;
            }
            .backward-txt-container {
                p {
                    font-size: 28px;
                }
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .backward-modal {
        .inner {
            figure {
                width: 35%;
            }
            .backward-txt-container {
                p {
                    font-size: 28px;
                }
            }
        }
    }
}
@media (orientation: portrait) {
    .backward-modal {
        .inner {
            figure {
                width: 50%;
            }
            .backward-txt-container {
                p {
                    font-size: 30px;
                }
            }
        }
    }
}
