.plot-modal-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    .inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .plot-modal {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 4rem 3rem 2rem;
            width: 80%;
            border-radius: 0.5rem;
            background-color: var(--bs-white);
            .flex-container {
                display: flex;
                align-items: center;
                flex-direction: column-reverse;
                gap: 20px;
                .title {
                    font-size: 1.5rem;
                    font-size: 32px;
                    font-weight: bold;
                    padding-bottom: 8px;
                    font-family: 'Maple';
                }
            }
            .book-preview {
                width: 280px;
                position: relative;
                flex-shrink: 0;
                figure {
                    position: absolute;
                }
                .book-cover {
                    z-index: 10;
                    height: 205px;
                    overflow: hidden;
                }
                .book-bottom {
                    position: relative;
                    bottom: 3px;
                    width: 110%;
                    left: 51%;
                    transform: translateX(-50%);
                    z-index: 1;
                }
                .book-name-container {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    z-index: 20;
                    .book-title {
                        font-size: 1.3rem;
                        font-weight: bold;
                        font-family: 'Maple';
                        text-shadow: -1px 0px #fff, 0px 1px #fff, 1px 0px #fff,
                            0px -1px #fff;
                    }
                }
            }
            .plot-container {
                width: 100%;
                .title {
                    font-size: 1.5rem;
                    font-size: 32px;
                    font-weight: bold;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #ddd;
                }
                .origin-story {
                    font-weight: bold;
                    margin: 20px 0 0;
                    font-size: 1.25rem;
                }
                .origin-story-desc {
                    color: #727272;
                    line-height: 1.4;
                    font-size: 20px;
                    margin: 20px 0;
                }
                .story-begin {
                    padding-bottom: 8px;
                    border-top: 1px solid #ddd;
                    font-size: 20px;
                    padding-top: 20px;
                    color: var(--bs-green-light);
                    font-family: 'Maple';
                }
                .desc {
                    line-height: 1.4;
                    margin: 10px 0 30px;
                    color: var(--text-dark);
                    font-size: 20px;
                    font-family: 'Maple';
                    font-weight: lighter;
                    color: #727272;
                }
                .choose-btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    padding: 1rem 1.7rem;
                    color: var(--bs-white);
                    border-radius: 0.5rem;
                    background-color: var(--bs-green-primary);
                    transition-duration: 0.3s;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    font-family: 'Maple';
                    width: 30%;
                    text-align: center;
                    font-size: 24px;
                    &:hover {
                        background-color: var(--bs-green-light);
                    }
                    figure {
                        width: 32px;
                    }
                }
            }
            .close-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                border: none;
                background-color: #ffc377;
                cursor: pointer;
                padding: 0.6rem;
                margin-left: auto;
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }
    }
}
// mediaQuery
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                width: 90%;
                height: 80%;
                overflow-y: scroll;
                .flex-container {
                    gap: 0px;
                    .title {
                        font-size: 40px;
                        font-weight: bold;
                        padding-bottom: 8px;
                    }
                }
                .book-preview {
                    width: 280px;
                    position: relative;
                    flex-shrink: 0;
                    figure {
                        position: absolute;
                    }
                    .book-cover {
                        z-index: 10;
                        height: 205px;
                        overflow: hidden;
                    }
                    .book-bottom {
                        position: relative;
                        bottom: 3px;
                        width: 110%;
                        left: 51%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    .book-name-container {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 20;
                    }
                }
                .plot-container {
                    width: 100%;
                    .title {
                        font-size: 1.5rem;
                        font-size: 32px;
                        font-weight: bold;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #ddd;
                    }
                    .origin-story {
                        font-weight: bold;
                        margin: 20px 0 0;
                        font-size: 1.25rem;
                    }
                    .origin-story-desc {
                        color: #727272;
                        line-height: 1.4;
                        font-size: 30px;
                        margin: 20px 0;
                    }
                    .story-begin {
                        padding-bottom: 8px;
                        border-top: 1px solid #ddd;
                        font-size: 30px;

                        padding-top: 20px;
                        color: var(--bs-green-light);
                    }
                    .desc {
                        line-height: 1.6;
                        margin: 10px 0 30px;
                        color: var(--text-dark);
                        font-size: 24px;
                        font-family: 'Maple';
                        font-weight: lighter;
                        color: #727272;
                        // 아래는 적용해보고 이상하면 수정할것!
                        max-height: 100px;
                        overflow-y: scroll;
                    }
                    .choose-btn {
                        width: 80%;
                        text-align: center;
                        padding: 1.5rem;
                        transform: translateX(-50%);
                        font-size: 24px;
                        font-family: 'Maple';
                        &:hover {
                            background-color: var(--bs-green-light);
                        }
                    }
                }
                .close-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: none;
                    background-color: #ffc377;
                    cursor: pointer;
                    padding: 0.8rem;
                    margin-left: auto;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                width: 90%;
                height: 80%;
                overflow-y: scroll;
                .flex-container {
                    gap: 0px;
                    .title {
                        font-size: 40px;
                        font-weight: bold;
                        padding-bottom: 8px;
                    }
                }
                .book-preview {
                    width: 280px;
                    position: relative;
                    flex-shrink: 0;
                    figure {
                        position: absolute;
                    }
                    .book-cover {
                        z-index: 10;
                        height: 205px;
                        overflow: hidden;
                    }
                    .book-bottom {
                        position: relative;
                        bottom: 3px;
                        width: 110%;
                        left: 51%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    .book-name-container {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 20;
                    }
                }
                .plot-container {
                    width: 100%;
                    .title {
                        font-size: 1.5rem;
                        font-size: 32px;
                        font-weight: bold;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #ddd;
                    }
                    .origin-story {
                        font-weight: bold;
                        margin: 20px 0 0;
                        font-size: 1.25rem;
                    }
                    .origin-story-desc {
                        color: #727272;
                        line-height: 1.4;
                        font-size: 30px;
                        margin: 20px 0;
                    }
                    .story-begin {
                        padding-bottom: 8px;
                        border-top: 1px solid #ddd;
                        font-size: 30px;

                        padding-top: 20px;
                        color: var(--bs-green-light);
                    }
                    .desc {
                        line-height: 1.6;
                        margin: 10px 0 30px;
                        color: var(--text-dark);
                        font-size: 24px;
                        font-family: 'Maple';
                        font-weight: lighter;
                        color: #727272;
                        // 아래는 적용해보고 이상하면 수정할것!
                        max-height: 100px;
                        overflow-y: scroll;
                    }
                    .choose-btn {
                        width: 80%;
                        text-align: center;
                        padding: 1.5rem;
                        transform: translateX(-50%);
                        font-size: 24px;
                        font-family: 'Maple';
                        &:hover {
                            background-color: var(--bs-green-light);
                        }
                    }
                }
                .close-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: none;
                    background-color: #ffc377;
                    cursor: pointer;
                    padding: 0.8rem;
                    margin-left: auto;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                width: 90%;
                height: 90%;
                overflow-y: scroll;
                .flex-container {
                    gap: 0px;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        padding-bottom: 8px;
                    }
                }
                .book-preview {
                    width: 200px;
                    position: relative;
                    flex-shrink: 0;
                    figure {
                        position: absolute;
                    }
                    .book-cover {
                        z-index: 10;
                        height: 205px;
                        overflow: hidden;
                    }
                    .book-bottom {
                        position: relative;
                        bottom: 3px;
                        width: 110%;
                        left: 51%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    .book-name-container {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 20;
                    }
                }
                .plot-container {
                    width: 100%;
                    .title {
                        font-size: 1.5rem;
                        font-size: 32px;
                        font-weight: bold;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #ddd;
                    }
                    .origin-story {
                        font-weight: bold;
                        margin: 20px 0 0;
                        font-size: 1.25rem;
                    }
                    .origin-story-desc {
                        color: #727272;
                        line-height: 1.4;
                        font-size: 30px;
                        margin: 20px 0;
                    }
                    .story-begin {
                        padding-bottom: 8px;
                        border-top: 1px solid #ddd;
                        font-size: 20px;
                        padding-top: 20px;
                        color: var(--bs-green-light);
                    }
                    .desc {
                        line-height: 1.6;
                        margin: 10px 0 30px;
                        color: var(--text-dark);
                        font-size: 20px;
                        font-family: 'Maple';
                        font-weight: lighter;
                        color: #727272;
                        max-height: 100px;
                        overflow-y: scroll;
                    }
                    .choose-btn {
                        width: 80%;
                        text-align: center;
                        padding: 1.5rem;
                        transform: translateX(-50%);
                        font-size: 18px;
                        font-family: 'Maple';
                        &:hover {
                            background-color: var(--bs-green-light);
                        }
                    }
                }
                .close-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: none;
                    background-color: #ffc377;
                    cursor: pointer;
                    padding: 0.8rem;
                    margin-left: auto;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                width: 90%;
                height: 90%;
                overflow-y: scroll;
                .flex-container {
                    gap: 0px;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        padding-bottom: 8px;
                    }
                }
                .book-preview {
                    width: 200px;
                    position: relative;
                    flex-shrink: 0;
                    figure {
                        position: absolute;
                    }
                    .book-cover {
                        z-index: 10;
                        height: 205px;
                        overflow: hidden;
                    }
                    .book-bottom {
                        position: relative;
                        bottom: 3px;
                        width: 110%;
                        left: 51%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    .book-name-container {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 20;
                    }
                }
                .plot-container {
                    width: 100%;
                    .title {
                        font-size: 1.5rem;
                        font-size: 32px;
                        font-weight: bold;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #ddd;
                    }
                    .origin-story {
                        font-weight: bold;
                        margin: 20px 0 0;
                        font-size: 1.25rem;
                    }
                    .origin-story-desc {
                        color: #727272;
                        line-height: 1.4;
                        font-size: 30px;
                        margin: 20px 0;
                    }
                    .story-begin {
                        padding-bottom: 8px;
                        border-top: 1px solid #ddd;
                        font-size: 20px;
                        padding-top: 20px;
                        color: var(--bs-green-light);
                    }
                    .desc {
                        line-height: 1.6;
                        margin: 10px 0 30px;
                        color: var(--text-dark);
                        font-size: 20px;
                        font-family: 'Maple';
                        font-weight: lighter;
                        color: #727272;
                        max-height: 100px;
                        overflow-y: scroll;
                    }
                    .choose-btn {
                        width: 80%;
                        text-align: center;
                        padding: 1.5rem;
                        transform: translateX(-50%);
                        font-size: 18px;
                        font-family: 'Maple';
                        &:hover {
                            background-color: var(--bs-green-light);
                        }
                    }
                }
                .close-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: none;
                    background-color: #ffc377;
                    cursor: pointer;
                    padding: 0.8rem;
                    margin-left: auto;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                width: 90%;
                height: 90%;
                overflow-y: scroll;
                .flex-container {
                    gap: 0px;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        padding-bottom: 8px;
                    }
                }
                .book-preview {
                    width: 200px;
                    position: relative;
                    flex-shrink: 0;
                    figure {
                        position: absolute;
                    }
                    .book-cover {
                        z-index: 10;
                        height: 205px;
                        overflow: hidden;
                    }
                    .book-bottom {
                        position: relative;
                        bottom: 3px;
                        width: 110%;
                        left: 51%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    .book-name-container {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 20;
                    }
                }
                .plot-container {
                    width: 100%;
                    .title {
                        font-size: 1.5rem;
                        font-size: 32px;
                        font-weight: bold;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #ddd;
                    }
                    .origin-story {
                        font-weight: bold;
                        margin: 20px 0 0;
                        font-size: 1.25rem;
                    }
                    .origin-story-desc {
                        color: #727272;
                        line-height: 1.4;
                        font-size: 30px;
                        margin: 20px 0;
                    }
                    .story-begin {
                        padding-bottom: 8px;
                        border-top: 1px solid #ddd;
                        font-size: 20px;
                        padding-top: 20px;
                        color: var(--bs-green-light);
                    }
                    .desc {
                        line-height: 1.6;
                        margin: 10px 0 30px;
                        color: var(--text-dark);
                        font-size: 20px;
                        font-family: 'Maple';
                        font-weight: lighter;
                        color: #727272;
                        max-height: 100px;
                        overflow-y: scroll;
                    }
                    .choose-btn {
                        width: 80%;
                        text-align: center;
                        padding: 1.5rem;
                        transform: translateX(-50%);
                        font-size: 18px;
                        font-family: 'Maple';
                        &:hover {
                            background-color: var(--bs-green-light);
                        }
                    }
                }
                .close-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: none;
                    background-color: #ffc377;
                    cursor: pointer;
                    padding: 0.8rem;
                    margin-left: auto;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 767px) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                width: 90%;
                height: 90%;
                overflow-y: scroll;
                .flex-container {
                    gap: 0px;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        padding-bottom: 8px;
                    }
                }
                .book-preview {
                    width: 200px;
                    position: relative;
                    flex-shrink: 0;
                    figure {
                        position: absolute;
                    }
                    .book-cover {
                        z-index: 10;
                        height: 205px;
                        overflow: hidden;
                    }
                    .book-bottom {
                        position: relative;
                        bottom: 3px;
                        width: 110%;
                        left: 51%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    .book-name-container {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        z-index: 20;
                    }
                }
                .plot-container {
                    width: 100%;
                    .title {
                        font-size: 1.5rem;
                        font-size: 32px;
                        font-weight: bold;
                        padding-bottom: 8px;
                        border-bottom: 1px solid #ddd;
                    }
                    .origin-story {
                        font-weight: bold;
                        margin: 20px 0 0;
                        font-size: 1.25rem;
                    }
                    .origin-story-desc {
                        color: #727272;
                        line-height: 1.4;
                        font-size: 30px;
                        margin: 20px 0;
                    }
                    .story-begin {
                        padding-bottom: 8px;
                        border-top: 1px solid #ddd;
                        font-size: 20px;
                        padding-top: 20px;
                        color: var(--bs-green-light);
                    }
                    .desc {
                        line-height: 1.6;
                        margin: 10px 0 30px;
                        color: var(--text-dark);
                        font-size: 20px;
                        font-family: 'Maple';
                        font-weight: lighter;
                        color: #727272;
                        max-height: 100px;
                        overflow-y: scroll;
                    }
                    .choose-btn {
                        width: 80%;
                        text-align: center;
                        padding: 1.5rem;
                        transform: translateX(-50%);
                        font-size: 18px;
                        font-family: 'Maple';
                        &:hover {
                            background-color: var(--bs-green-light);
                        }
                    }
                }
                .close-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: none;
                    background-color: #ffc377;
                    cursor: pointer;
                    padding: 0.8rem;
                    margin-left: auto;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .plot-modal-section {
        .inner {
            .plot-modal {
                height: auto;
            }
        }
    }
}
