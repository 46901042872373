.book-item-test {
    width: 300px;
    position: relative;
    transition-duration: 0.3s;
    cursor: pointer;
    .text-music-btn {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 80px;
        z-index: 10;
        border: none;
        background: none;
        cursor: pointer;
    }

    figure {
        position: absolute;
        &.bean-circle {
            width: 54px;
            top: 10px;
            right: 10px;
            z-index: 10;
        }
    }
    .book-cover {
        z-index: 10;
        top: -1.5px;
        left: 2px;
        width: 296px;
        height: 222px;
        overflow: hidden;
    }
    .book-bottom {
        position: relative;
        bottom: 3px;
        width: 110%;
        left: 51%;
        transform: translateX(-50%);
        z-index: 1;
    }
    .book-name-container {
        width: 100%;
        position: absolute;
        top: 64%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 20;
        .book-title {
            font-family: 'Maple';
            display: inline-block;
            color: #fff;
            font-size: 1.7rem;
            line-height: 1.2;
            position: relative;
            z-index: 10;
            .book-title-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                -webkit-text-stroke: 8px var(--bs-green-light);
            }
        }
        .book-date {
            font-family: 'Maple';
            font-size: 1rem;
            text-shadow: -1px 0px white, 0px 1px white, 1px 0px white,
                0px -1px white;
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
}
// mediaQuery | 2024-02-06 수정
@media screen and (max-width: 1440px) {
    .book-item-test {
        width: 30%;
        .book-cover {
            width: 100%;
            height: auto;
            left: -1px;
        }
        .book-bottom {
            width: 112%;
        }
    }
}
// 2024-02-06 | mediaQuery 추가
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .book-item-test {
        .text-music-btn {
            width: 80px;
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .book-item-test {
        .text-music-btn {
            top: 4px;
            left: 0px;
            width: 76px;
        }
    }
}
@media screen and (max-width: 900px) {
    .book-item-test {
        .text-music-btn {
            top: 4px;
            left: 0px;
            width: 70px;
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .book-item-test {
        width: 47%;
    }
    .book-item-test {
        .book-name-container {
            .book-title {
                font-size: 2rem;
            }
        }
        .text-music-btn {
            top: 4px;
            left: 0px;
            width: 80px;
        }
    }
}
