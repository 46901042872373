.stories-section {
    position: relative;
    min-width: 1340px;
    height: 100vh;
    overflow: hidden;
    background-color: #000;
    .arrow-button {
        top: 43%;
    }
    .text-music-btn {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: scaleX(-1);
        width: 140px;
        background: none;
        border: none;
        cursor: pointer;
    }
    .back-mkid {
        position: absolute;
        z-index: 99;
        top: 40px;
        left: 40px;
        border: none;
        font-family: 'Maple';
        font-size: 30px;
        padding: 1rem;
        border-radius: 8px;
        cursor: pointer;
        color: #fff;
        background-color: #134388;
    }
    .stories-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        background-color: #000;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        img {
            height: 100vh;
            object-fit: cover;
        }
    }
    .choice-btns {
        width: calc(100% - 40px);
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        .right-btn {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .ballon {
            position: relative;
            top: -15px;
            padding: 24px;
            background-color: rgba($color: #f0f0f0, $alpha: 0.6);
            border-radius: 10px;
            font-size: 34px;
            text-align: center;
            font-family: 'Maple';
            &::before {
                content: '';
                position: absolute;
                top: 100%;
                left: 20%;
                margin-left: -15px;
                border-width: 10px;
                border-style: solid;
                border-color: rgba($color: #f0f0f0, $alpha: 0.6) transparent
                    transparent transparent;
            }
        }
        .ballon.right {
            right: 0;
        }
        .ballon.right::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 90%;
            margin-left: -15px;
            border-width: 10px;
            border-style: solid;
            border-color: rgba($color: #f0f0f0, $alpha: 0.6) transparent
                transparent transparent;
        }
        .btn {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            color: var(--bs-white);
            background-color: salmon;
            padding: 1.5rem 2rem;
            border-radius: 0.5rem;
            transition-duration: 0.2s;
            figure {
                width: 24px;
                height: 24px;
            }
            span {
                font-size: 33px;
                font-family: 'Maple';
            }
        }
        .go-choice-modal {
            background-color: #3c3c3c;
            &:hover {
                background-color: #212121;
            }
        }
        .open-image-modal {
            background-color: var(--bs-green-light);
            &:hover {
                background-color: var(--bs-green-primary);
            }
        }
    }
    .story-contents {
        width: 90%;
        text-align: center;
        padding: 1rem;
        border-radius: 0.4rem;
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translateX(-50%);
        font-size: 1.8rem;
        .origin-text {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 8px #fff;
                z-index: -1;
            }
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 10rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 24px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
    .numbering {
        position: absolute;
        bottom: 50px;
        right: 50px;
        color: #fff;
        font-size: 1.8rem;
        font-family: 'Maple';
    }
}
// mediaQuery
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .stories-section {
        min-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .text-music-btn {
            width: 130px;
            // position: absolute;
            // top: 140px;
            // right: 10px;
            // transform: scaleX(-1);
            // width: 130px;
            // background: none;
            // border: none;
        }
        .stories-bg {
            img {
                height: 100vh;
                object-fit: cover;
            }
        }
        .story-contents {
            width: 100%;
            bottom: 50px;
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 6rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 20px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .stories-section {
        min-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .text-music-btn {
            width: 120px;
            // position: absolute;
            // top: 80px;
            // right: 10px;
            // transform: scaleX(-1);
            // width: 120px;
            // background: none;
            // border: none;
        }
        .stories-bg {
            img {
                height: 100vh;
                object-fit: cover;
            }
        }
        .story-contents {
            width: 100%;
            bottom: 50px;
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 6rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 20px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .stories-section {
        min-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .text-music-btn {
            width: 120px;
            // position: absolute;
            // top: 80px;
            // right: 10px;
            // transform: scaleX(-1);
            // width: 120px;
            // background: none;
            // border: none;
        }
        .back-mkid {
            position: absolute;
            z-index: 99;
            top: 30px;
            left: 30px;
            border: none;
            font-family: 'Maple';
            font-size: 26px;
            padding: 1rem;
            border-radius: 8px;
            cursor: pointer;
            color: #fff;
            background-color: #134388;
        }
        .choice-btns {
            .btn {
                padding: 1rem;
                span {
                    font-size: 24px;
                }
            }
            .ballon {
                padding: 1rem;
                font-size: 24px;
            }
        }
        .stories-bg {
            img {
                height: 100vh;
                object-fit: cover;
            }
        }
        .story-contents {
            width: 100%;
            bottom: 30px;
        }
        .numbering {
            bottom: 20px;
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 6rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 20px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .stories-section {
        min-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .text-music-btn {
            width: 120px;
            // position: absolute;
            // top: 80px;
            // right: 10px;
            // transform: scaleX(-1);
            // width: 120px;
            // background: none;
            // border: none;
        }
        .back-mkid {
            position: absolute;
            z-index: 99;
            top: 30px;
            left: 30px;
            border: none;
            font-family: 'Maple';
            font-size: 26px;
            padding: 1rem;
            border-radius: 8px;
            cursor: pointer;
            color: #fff;
            background-color: #134388;
        }
        .choice-btns {
            .btn {
                padding: 1rem;
                span {
                    font-size: 24px;
                }
            }
            .ballon {
                padding: 1rem;
                font-size: 24px;
            }
        }
        .stories-bg {
            img {
                height: 100vh;
                object-fit: cover;
            }
        }
        .story-contents {
            width: 100%;
            bottom: 30px;
        }
        .numbering {
            bottom: 20px;
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 6rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 20px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .stories-section {
        min-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .text-music-btn {
            width: 120px;
            // position: absolute;
            // top: 80px;
            // right: 10px;
            // transform: scaleX(-1);
            // width: 120px;
            // background: none;
            // border: none;
        }
        .back-mkid {
            position: absolute;
            z-index: 99;
            top: 30px;
            left: 30px;
            border: none;
            font-family: 'Maple';
            font-size: 26px;
            padding: 1rem;
            border-radius: 8px;
            cursor: pointer;
            color: #fff;
            background-color: #134388;
        }
        .choice-btns {
            .btn {
                padding: 1rem;
                span {
                    font-size: 24px;
                }
            }
            .ballon {
                padding: 1rem;
                font-size: 24px;
            }
        }
        .stories-bg {
            img {
                height: 100vh;
                object-fit: cover;
            }
        }
        .story-contents {
            width: 100%;
            bottom: 30px;
        }
        .numbering {
            bottom: 20px;
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 6rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 20px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .stories-section {
        min-width: 100%;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .text-music-btn {
            width: 120px;
            // position: absolute;
            // top: 140px;
            // right: 10px;
            // transform: scaleX(-1);
            // width: 120px;
            // background: none;
            // border: none;
        }
        .back-mkid {
            position: absolute;
            z-index: 99;
            top: 30px;
            left: 30px;
            border: none;
            font-family: 'Maple';
            font-size: 26px;
            padding: 1rem;
            border-radius: 8px;
            cursor: pointer;
            color: #fff;
            background-color: #134388;
        }
        .choice-btns {
            .btn {
                padding: 1rem;
                span {
                    font-size: 24px;
                }
            }
            .ballon {
                padding: 1rem;
                font-size: 24px;
            }
        }
        .stories-bg {
            img {
                height: 100vh;
                object-fit: cover;
            }
        }
        .story-contents {
            width: 100%;
            bottom: 30px;
        }
        .numbering {
            bottom: 20px;
        }
        &.title {
            line-height: 1.6;
            position: relative;
            font-size: 2.7rem;
            font-weight: bold;
            z-index: 10;
            font-family: 'Maple';
            bottom: none;
            top: 50%;
            padding: 0;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 6rem;
            .text-stroke {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-text-stroke: 20px var(--bs-green-primary);
                z-index: -1;
            }
        }
    }
}
