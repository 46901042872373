.arrow-button {
    width: 70px;
    height: 70px;
    background-color: var(--bs-green-secondary);
    border: none;
    color: var(--bs-white);
    border-radius: 50%;
    font-size: 1.25rem;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transition-duration: 0.3s;
    z-index: 90;
    cursor: pointer;
    &:hover {
        background-color: var(--bs-green-primary);
    }
}

.left {
    left: 20px;
    background-color: #bfbfbf;
    &:hover {
        background-color: #000;
    }
}

.right {
    right: 20px;
}
