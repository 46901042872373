.heart {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: red;
    transform: rotate(-45deg);
    animation: popHeart 0.5s forwards;
    z-index: 9999999;
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: red;
        border-radius: 50%;
    }
    &::before {
        top: -12px;
        left: 0;
    }
    &::after {
        left: 12px;
    }
}

@keyframes popHeart {
    0% {
        opacity: 1;
        transform: scale(1) rotate(-45deg);
    }
    50% {
        opacity: 0.5;
        transform: scale(1.2) rotate(-45deg);
    }
    100% {
        opacity: 0;
        transform: scale(0.5) rotate(-45deg);
    }
}
