.error-modal-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .inner {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        .sleeping-robot {
            width: 30%;
        }
        .error-text-container {
            width: 80%;
            background-color: var(--bs-white);
            padding: 2rem;
            border-radius: 0.5rem;
            text-align: center;
            .main-txt {
                font-size: 28px;
                margin-bottom: 1rem;
                line-height: 1.4;
                font-family: 'Maple';
            }
            .close-btn {
                background-color: var(--bs-blue-primary);
                background-color: var(--bs-green-primary);
                color: var(--bs-white);
                display: inline-flex;
                cursor: pointer;
                font-family: 'Maple';
                padding: 0.6rem 2rem;
                border-radius: 6px;
            }
        }
    }
}
