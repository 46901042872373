.fairy-tale-item {
    list-style: none;
    width: 300px;
    position: relative;
    transition-duration: 0.3s;
    cursor: pointer;
    .text-music-btn {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 80px;
        z-index: 10;
        border: none;
        background: none;
        cursor: pointer;
    }
    figure {
        position: absolute;
        &.bean-circle {
            width: 54px;
            top: 10px;
            right: 10px;
            z-index: 10;
        }
    }
    .book-cover {
        z-index: 10;
        top: -1.5px;
        left: 2px;
        width: 296px;
        height: 222px;
        overflow: hidden;
    }
    .book-bottom {
        position: relative;
        bottom: 3px;
        width: 110%;
        left: 51%;
        transform: translateX(-50%);
        z-index: 1;
    }
    .book-name-container {
        width: 100%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 20;
        cursor: pointer;
        .book-title {
            font-family: 'Maple';
            display: inline-block;
            color: #fff;
            padding: 0.5rem;
            font-size: 1.7rem;
            line-height: 1.2;
            text-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.7);
            cursor: pointer;
        }
    }
    .hover-btns {
        width: 99%;
        height: 85%;
        background-color: rgba($color: #000000, $alpha: 0.7);
        position: absolute;
        top: -2px;
        left: 2px;
        z-index: 21;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        display: none;
        font-family: 'Maple';
        .hover-btn {
            width: 220px;
            font-size: 1.5rem;
            height: 56px;
            background-color: var(--bs-white);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .choose {
            color: var(--bs-white);
            background-color: var(--bs-green-light);
            transition-duration: 0.3s;
            display: flex;
            gap: 0.3rem;
            &:hover {
                background-color: var(--bs-green-primary);
            }
            figure {
                position: initial;
                width: 28px;
            }
        }
        .around {
            transition-duration: 0.3s;
            &:hover {
                background-color: #c5c5c5;
            }
        }
    }
    &:hover .hover-btns {
        display: flex;
    }
}
// mediaQuery | 2024-02-06 수정
@media screen and (max-width: 1440px) {
    .fairy-tale-item {
        width: 30%;
        .book-cover {
            width: 100%;
            height: auto;
            left: -1px;
        }
        .book-bottom {
            width: 112%;
        }
        .hover-btns {
            width: 100%;
            left: -1px;
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .fairy-tale-item {
        width: 47%;
        .book-name-container {
            .book-title {
                font-size: 2rem;
            }
        }
    }
}
