.fairy-tale-section {
    width: 100%;
    min-height: 100vh;
    background-color: #f3e6e7;
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    .title-container {
        text-align: center;
        margin-bottom: 2rem;
        h3 {
            font-size: 2rem;
            color: var(--bs-green-primary);
            font-weight: bold;
            font-family: 'Maple';
        }
        p {
            margin: 0.8rem 0;
            color: var(--text-dark);
            font-size: 1.25rem;
            font-family: 'Maple';
        }
    }
    .tab-list {
        display: flex;
        gap: 1rem;
        border-bottom: 1px solid #005e43;
        li {
            color: #686868;
            padding: 0.5rem 1rem;
            cursor: pointer;
            margin-bottom: -1px;
            border-bottom-color: #ffe6c0;
            font-size: 1.5rem;
            font-family: 'Maple';
            &.active {
                border: 1px solid #005e43;
                margin-bottom: -1px;
                border-bottom-color: #ffe6c0;
                color: #005e43;
                font-weight: bold;
            }
        }
    }
    .fairy-tale-list {
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 26px;
        padding-bottom: 240px;
    }
    .bottom {
        width: 100%;
        position: absolute;
        bottom: -80px;
    }
    // 2024-02-14 | footer-text 추가
    .footer-txt {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: gray;
        padding-bottom: 1.25rem;
        font-weight: bold;
        .site {
            cursor: pointer;
        }
    }
}
// mediaQuery
@media screen and (max-width: 1440px) {
    .fairy-tale-section {
        padding-top: 120px;
        .inner {
            width: 90%;
            .fairy-tale-list {
                width: 100%;
                justify-content: space-between;
                .fairy-tale-item .hover-btns .hover-btn {
                    width: 90%;
                    height: 80px;
                    font-size: 24px;
                }
            }
            .fairy-tale-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
        }
    }
}
