// 2024.01.23 | 동화 최종 완성 시 나오는 모달 css
.complete-section {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba($color: #000000, $alpha: 0.5);
    .inner {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .blink {
            animation: blink 1s linear infinite;
        }
        .hand {
            animation: up-hand 0.5s linear infinite alternate;
        }
        .complete-character {
            position: relative;
            top: 3rem;
        }
        .text-container {
            width: 100%;
            padding: 2rem;
            background-color: var(--bs-white);
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            bottom: 3rem;
            h2 {
                font-family: 'Maple';
                font-size: 3rem;
                margin-bottom: 2rem;
                color: var(--bs-green-light);
            }
            .btn-container {
                display: flex;
                justify-content: center;
                gap: 1rem;
                width: 100%;
                li {
                    font-family: 'Maple';
                    font-size: 1.5rem;
                    padding: 2rem;
                    width: 30%;
                    background-color: var(--bs-green-light);
                    text-align: center;
                    border-radius: 8px;
                    cursor: pointer;
                    &:nth-child(2) {
                        background-color: #e6e7a7;
                    }
                }
            }
        }
        @keyframes blink {
            50% {
                opacity: 0;
            }
        }
        @keyframes up-hand {
            100% {
                transform: translateY(-2px);
            }
        }
    }
}
// mediaQuery
// 2024-02-16 | 미디어쿼리 추가
@media screen and (min-width: 1441px) and (max-width: 1540px) {
    .complete-section {
        .inner {
            .complete-character {
                top: 7rem;
            }
            .text-container {
                bottom: 7rem;
            }
        }
    }
}
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .com-svg {
        width: 300px;
    }
    .complete-section .inner .text-container {
        width: 90%;
        bottom: 9rem;
    }
    .complete-section .inner .text-container .btn-container li {
        padding: 1rem;
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .com-svg {
        width: 300px;
    }
    .complete-section .inner .text-container {
        width: 90%;
        bottom: 9rem;
    }
    .complete-section .inner .text-container .btn-container li {
        padding: 1rem;
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .com-svg {
        width: 230px;
    }
    .complete-section .inner .text-container {
        width: 90%;
        bottom: 14rem;
        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    }
    .complete-section .inner .text-container .btn-container li {
        padding: 1rem;
    }
}

// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 900px) {
    .com-svg {
        width: 400px;
    }
    .complete-section .inner .text-container {
        width: 90%;
        bottom: 14rem;
        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    }
    .complete-section .inner .text-container .btn-container li {
        padding: 1rem;
        display: flex;
        flex-shrink: 1;
        align-items: center;
        justify-content: center;
    }
}
