.alert-modal {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    // 2024-02-13 | 닫기버튼 추가 css
    .close-modal-btn {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffc377;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        padding: 0.8rem;
        position: absolute;
        right: 100px;
        top: 50px;
    }
    // 2024-02-16 | wizard 이미지 위치 조정
    .wizard {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 60px;
    }
    .pot {
        position: absolute;
        top: 420px;
        left: 50%;
        display: none;
    }
    .text-container {
        width: 50%;
        min-height: 200px;
        padding: 2rem;
        background-color: #f5f5f5;
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 195px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        .title {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.2;
            margin-bottom: 1rem;
            font-family: 'Maple';
        }
        .tip {
            font-weight: lighter;
        }
        p {
            width: 100%;
            text-align: center;
            font-size: 1.4rem;
            font-family: 'Maple';
        }
    }
    .btn {
        border: none;
        background-color: var(--bs-green-light);
        color: var(--bs-white);
        padding: 1rem 3rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 120px;
        border-radius: 4px;
        transition-duration: 0.3s;
        font-family: 'Maple';
        width: 20%;
        text-align: center;
        font-size: 28px;
        &:hover {
            background-color: var(--bs-green-primary);
        }
    }
    .arm {
        animation: move-arm 1s infinite alternate;
    }
    @keyframes move-arm {
        0% {
            transform: rotate(1deg);
        }
        100% {
            transform: rotate(2deg);
        }
    }
    .bubble {
        animation: bubble-move 0.9s infinite alternate;
    }
    @keyframes bubble-move {
        0% {
            transform: scale(0.9);
            transform-origin: center center;
        }
        100% {
            transform: scale(1);
            transform-origin: center center;
        }
    }
}
// mediaQuery
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .alert-modal {
        .wizard {
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            svg {
                width: 330px;
            }
        }
        .pot {
            display: none;
        }
        .text-container {
            width: 65%;
            bottom: 135px;
        }
        .btn {
            bottom: 60px;
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .alert-modal {
        .wizard {
            left: 50%;
            transform: translateX(-50%);
            top: -50px;
            svg {
                width: 300px;
            }
        }
        .text-container {
            width: 80%;
            margin-bottom: 1rem;
            bottom: 145px;
            .title {
                font-size: 2rem;
                margin-bottom: 1.8rem;
            }
            .tips {
                font-size: 1.8rem;
                color: #888;
            }
        }
        .btn {
            width: 80%;
            padding: 1.5rem;
            text-align: center;
            font-size: 28px;
            bottom: 70px;
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .alert-modal {
        .wizard {
            left: 50%;
            transform: translateX(-50%);
            top: -90px;
            svg {
                width: 240px;
            }
        }
        .pot {
            display: none;
        }
        .text-container {
            width: 90%;
            min-height: auto;
            margin-bottom: 1rem;
            padding: 1rem;
            bottom: 115px;
            .title {
                font-size: 1.5rem;
                margin-bottom: 1.8rem;
            }
            .tips {
                font-size: 1.2rem;
                color: #888;
            }
        }
        .btn {
            width: 90%;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.5rem;
            bottom: 50px;
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .alert-modal {
        .wizard {
            left: 50%;
            transform: translateX(-50%);
            top: -90px;
            svg {
                width: 240px;
            }
        }
        .pot {
            display: none;
        }
        .text-container {
            width: 90%;
            min-height: auto;
            margin-bottom: 1rem;
            padding: 1rem;
            bottom: 115px;
            .title {
                font-size: 1.5rem;
                margin-bottom: 1.8rem;
            }
            .tips {
                font-size: 1.2rem;
                color: #888;
            }
        }
        .btn {
            width: 90%;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.5rem;
            bottom: 50px;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .alert-modal {
        .wizard {
            left: 50%;
            transform: translateX(-50%);
            top: -90px;
            svg {
                width: 240px;
            }
        }
        .pot {
            display: none;
        }
        .text-container {
            width: 90%;
            min-height: auto;
            margin-bottom: 1rem;
            padding: 1rem;
            bottom: 115px;
            .title {
                font-size: 1.5rem;
                margin-bottom: 1.8rem;
            }
            .tips {
                font-size: 1.2rem;
                color: #888;
            }
        }
        .btn {
            width: 90%;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.5rem;
            bottom: 50px;
        }
    }
}
// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 767px) {
    .alert-modal {
        .wizard {
            left: 50%;
            transform: translateX(-50%);
            top: -90px;
            svg {
                width: 400px;
            }
        }
        .pot {
            display: none;
        }
        .text-container {
            width: 90%;
            min-height: auto;
            margin-bottom: 1rem;
            padding: 1rem;
            bottom: 115px;
            .title {
                font-size: 1.5rem;
                margin-bottom: 1.8rem;
            }
            .tips {
                font-size: 1.2rem;
                color: #888;
            }
        }
        .btn {
            width: 90%;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.5rem;
            bottom: 50px;
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .alert-modal {
        .wizard {
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .text-container {
            bottom: 30%;
        }
        .btn {
            bottom: 23%;
        }
    }
}
