footer{
    background-color: var(--bs-green-primary);
    .inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        .footer-logo{
            width: 140px;
        }
        .copy-text{
            font-size: 14px;
            color: var(--bs-white);
            margin: 1.5rem 0  2rem;
        }
        .footer-icon-container{
            display: flex;
            gap: 2rem;
            img{
                width: 24px;
            }
        }
    }
}