.choice-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    .go-back {
        padding: 1rem;
        position: absolute;
        left: 20px;
        top: 100px;
        font-family: 'Maple';
        font-size: 1.2rem;
        border-radius: 4px;
        background-color: lightgray;
        opacity: 0.9;
        cursor: pointer;
        z-index: 30;
    }
    .inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // 2024-02-13 | position 추가
        position: relative;
        // 2024-02-13 | 닫기버튼 추가 css
        .close-modal-btn {
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffc377;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            padding: 0.8rem;
            position: absolute;
            right: 20px;
            top: 50px;
        }
        .question-container {
            margin-bottom: 2rem;
            width: 80%;
            .origin-text {
                line-height: 1.3;
                position: relative;
                font-size: 2.2rem;
                font-weight: bold;
                z-index: 10;
                text-align: center;
                font-family: 'Maple';
                .text-stroke {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    -webkit-text-stroke: 6px #fff;
                    z-index: -1;
                    text-align: center;
                }
            }
        }
        .choice-btn-container {
            display: flex;
            width: 100%;
            gap: 2rem;
            .choice {
                width: 100%;
                background-color: rgba($color: #ffffff, $alpha: 0.9);
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 0rem;
                padding: 0rem 0rem;
                border-radius: 0.5rem;
                transition-duration: 0.3s;
                overflow: hidden;
                text-align: center;
                line-height: 1.4;
                figure {
                    max-height: 320px;
                    overflow: hidden;
                }
                h3 {
                    font-size: 3rem;
                    font-weight: bold;
                    font-family: 'Maple';
                    padding: 2rem;
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    &.small-tit {
                        font-size: 2rem;
                    }
                }
                p {
                    line-height: 1.4;
                    color: var(--text-dark);
                    font-size: 1.3rem;
                    font-family: 'Maple';
                }
                &:hover {
                    background-color: var(--bs-green-light);
                    color: var(--bs-white);
                    p {
                        color: var(--bs-white);
                    }
                }
                &.choice-1 {
                    background-color: #a1cef2;
                }
                &.choice-2 {
                    background-color: #afdfdf;
                }
                &.border {
                    border: 10px solid rgb(255, 255, 0);
                }
            }
        }
        .confirm-choice-btn {
            background-color: var(--bs-green-light);
            border: none;
            padding: 2rem 6rem;
            font-family: 'Maple';
            color: var(--bs-white);
            border-radius: 8px;
            margin-top: 2rem;
            cursor: pointer;
            transition: background-color 0.5s;
            font-size: 26px;
            animation: blink 0.5s ease-in-out infinite alternate;
            &:hover {
                background-color: var(--bs-green-primary);
            }
        }
    }
    @keyframes blink {
        0% {
            background-color: var(--bs-green-primary);
        }
        100% {
            background-color: var(--bs-green-light);
        }
    }
}
// mediaQuery
// 2024-02-16 | 미디어쿼리 추가
@media screen and (min-width: 1441px) and (max-width: 1540px) {
    .choice-section {
        .inner {
            .question-container {
                .origin-text {
                    font-size: 2rem;
                }
            }
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 2.6rem;
                        padding: 1.5rem;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    // 13인치 맥북 기준
    .choice-section {
        .inner {
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 2rem;
                        padding: 0.5rem;
                    }
                }
            }
            .confirm-choice-btn {
                padding: 1.5rem 5rem;
                font-size: 22px;
            }
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .choice-section {
        .inner {
            .question-container {
                .origin-text {
                    font-size: 2.2rem;
                }
            }
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 2.2rem;
                        padding: 1rem;
                    }
                }
            }
            .confirm-choice-btn {
                padding: 1.5rem 5rem;
                font-size: 22px;
            }
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .choice-section {
        .inner {
            .question-container {
                .origin-text {
                    font-size: 2rem;
                }
            }
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 1.8rem;
                        padding: 1rem;
                    }
                }
            }
            .confirm-choice-btn {
                padding: 1.5rem 5rem;
                font-size: 24px;
                margin-top: 1.25rem;
            }
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .choice-section {
        .inner {
            .question-container {
                .origin-text {
                    font-size: 1.8rem;
                }
            }
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 1.6rem;
                        padding: 1rem;
                    }
                }
            }
            .confirm-choice-btn {
                padding: 1.5rem 5rem;
                font-size: 24px;
                margin-top: 1.25rem;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .choice-section {
        .inner {
            .question-container {
                .origin-text {
                    font-size: 1.8rem;
                }
            }
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 1.6rem;
                        padding: 1rem;
                    }
                }
            }
            .confirm-choice-btn {
                padding: 1.5rem 5rem;
                font-size: 24px;
                margin-top: 1.25rem;
            }
        }
    }
}
// 2024-02-06 | 미디어쿼리 추가
@media screen and (max-width: 767px) {
    .choice-section {
        .inner {
            .question-container {
                .origin-text {
                    font-size: 1.8rem;
                }
            }
            .choice-btn-container {
                .choice {
                    h3 {
                        font-size: 1.6rem;
                        padding: 1rem;
                    }
                }
            }
            .confirm-choice-btn {
                padding: 1.5rem 5rem;
                font-size: 24px;
                margin-top: 1.25rem;
            }
        }
    }
}
