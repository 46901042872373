.confirm-section {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // 2024-02-13 | position 추가
        position: relative;
        // 2024-02-13 | 닫기버튼 추가 css
        .close-modal-btn {
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffc377;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            padding: 0.8rem;
            position: absolute;
            right: 20px;
            top: 50px;
        }
        .confirm-images {
            width: 36%;
            margin-bottom: 1.5rem;
            animation: book-move 1s infinite
                cubic-bezier(0.4, -0.06, 0.35, 1.18) alternate;
        }
        .confirm-txt-container {
            padding: 1.5rem;
            background-color: var(--bs-white);
            width: 80%;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .confirm-txt {
                text-align: center;
                font-family: 'Maple';
                font-size: 36px;
                line-height: 1.4;
                em {
                    color: var(--bs-green-secondary);
                }
            }
            button {
                border: none;
                font-family: 'Maple';
                padding: 1rem 1.2rem;
                font-size: 24px;
                border-radius: 4px;
                display: inline-block;
                width: 30%;
                margin: 0 auto;
                background-color: var(--bs-green-secondary);
                color: var(--bs-white);
                font-weight: lighter;
                transition-duration: 0.3s;
                cursor: pointer;
                &:hover {
                    background-color: var(--bs-green-primary);
                }
            }
        }
    }
    @keyframes book-move {
        100% {
            transform: translateY(-10px);
        }
    }
}
// mediaQuery
// 2024-02-16 | 미디어쿼리 추가
@media screen and (min-width: 1441px) and (max-width: 1540px) {
    .confirm-section {
        .inner {
            .confirm-images {
                width: 30%;
                margin-bottom: 1rem;
            }
        }
    }
}
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .confirm-section {
        .inner {
            .confirm-images {
                width: 30%;
                margin-bottom: 1rem;
            }
            .confirm-txt-container {
                .confirm-txt {
                    font-size: 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .confirm-section {
        .inner {
            .confirm-images {
                width: 30%;
            }
            .confirm-txt-container {
                .confirm-txt {
                    font-size: 32px;
                }
                button {
                    width: 40%;
                }
            }
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .confirm-section {
        .inner {
            .confirm-images {
                width: 25%;
            }
            .confirm-txt-container {
                padding: 1rem;
                .confirm-txt {
                    font-size: 24px;
                }
                button {
                    width: 40%;
                }
            }
        }
    }
}
@media screen and (max-width: 901px) {
    .confirm-section {
        .inner {
            .confirm-images {
                width: 50%;
            }
            .confirm-txt-container {
                padding: 1rem;
                .confirm-txt {
                    font-size: 26px;
                }
                button {
                    width: 40%;
                }
            }
        }
    }
}
