.image-modal-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    // 2024-02-13 | 닫기버튼 추가 css
    .close-modal-btn {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffc377;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        padding: 0.8rem;
        position: absolute;
        right: 20px;
        top: 50px;
    }
    .modal-container {
        padding: 1.8rem 1.25rem;
        width: 80%;
        border-radius: 1rem;
        background-color: var(--bs-white);
        .modal-title {
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            color: var(--bs-green-primary);
            margin-bottom: 1.5rem;
            position: relative;
            font-family: 'Maple';
        }
        .modal-title::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 30%;
            height: 2px;
            background-color: #cacaca;
        }
        .ai-image-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            .ai-image-box {
                width: 48%;
                position: relative;
                .ai-image {
                    width: 100%;
                    opacity: 50%;
                    &.selected {
                        opacity: 100%;
                    }
                }
                .origin-text {
                    position: absolute;
                    top: 20px;
                    width: 100%;
                    text-align: center;
                    font-size: 2rem;
                    font-weight: bold;
                    z-index: 10;
                    font-family: 'Maple';
                    &.new {
                        color: #2f4f2b;
                    }
                }
                .text-stroke {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    -webkit-text-stroke: 8px #fff;
                    z-index: -1;
                    &.new {
                        -webkit-text-stroke: 8px #efda78;
                    }
                }
            }
        }
        .choose-btn-container {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-top: 1rem;
            .class-btns {
                padding: 1rem 1.5rem;
                border: none;
                color: var(--bs-white);
                border-radius: 0.5rem;
                cursor: pointer;
                font-family: 'Maple';
                font-size: 1.25rem;
            }
            .re-make {
                background-color: #bfbfbf;
            }
            .confirm-image {
                background-color: var(--bs-green-primary);
            }
            .confirm-image.disabled {
                background-color: #bfbfbf;
            }
        }
    }
}
// mediaQuery
@media screen and (max-width: 1440px) {
    .image-modal-section {
        .modal-container {
            width: 70%;
        }
        .choose-btn-container {
            .class-btns {
                font-size: 1.4rem;
            }
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .image-modal-section {
        .modal-container {
            .ai-image-container {
                flex-direction: column;
                .ai-image-box {
                    width: 100%;
                }
            }
        }
    }
}