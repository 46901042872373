@font-face {
    font-family: 'EASTARJET-DemiLight';
    font-style: normal;
    font-weight: 300;
    src: url('./EASTARJET-DemiLight.eot') format('eot');
}
@font-face {
    font-family: 'EASTARJET-Medium';
    font-style: normal;
    font-weight: 500;
    src: url('./EASTARJET-Medium.eot') format('eot');
}
@font-face {
    font-family: 'EASTARJET-Heavy';
    font-style: normal;
    font-weight: 900;
    src: url('./EASTARJET-Heavy.eot') format('eot');
}
@font-face {
    font-family: 'Katuri';
    font-style: normal;
    font-weight: normal;
    src: url('./Katuri.eot'), url('./Katuri.woff') format('woff');
}
@font-face {
    font-family: 'Maple';
    font-style: normal;
    font-weight: lighter;
    src: url('./MaplestoryLight.eot'),
        url('./MaplestoryLight.woff') format('woff');
}
@font-face {
    font-family: 'Maple';
    font-style: normal;
    font-weight: bold;
    src: url('./MaplestoryBold.eot'),
        url('./MaplestoryBold.woff') format('woff');
}
