.storyland-section {
    background-color: #f1f6fb;
    width: 100%;
    min-height: 100vh;
    padding: 15vh 0;
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    .inner {
        max-width: 1320px;
        padding-bottom: 300px;
    }
    .library-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 0 16px;
        border-bottom: 1px solid var(--bs-green-primary);
        margin-bottom: 40px;
        h3 {
            font-size: 3rem;
            font-weight: 700;
            color: var(--bs-green-primary);
            font-family: 'Maple';
        }
        h6 {
            padding: 0 0 0 16px;
            font-size: 1.6rem;
            font-weight: 500;
            font-family: 'Maple';
            margin-top: 0.8rem;
            em {
                color: var(--bs-green-light);
                font-weight: bold;
            }
        }
        .make-story-btn {
            padding: 0.8rem 1.2rem;
            background-color: var(--bs-green-primary);
            border-radius: 4px;
            color: var(--bs-white);
            transition-duration: 0.3s;
            cursor: pointer;

            &:hover {
                background-color: var(--bs-green-light);
                cursor: pointer;
            }
        }
    }
    .book-category {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        margin-bottom: 40px;
        li {
            display: flex;
            align-items: center;
            padding: 0rem 2rem;
            border-radius: 0.5rem;
            gap: 0.5rem;
            cursor: pointer;
            figure {
                width: 100px;
            }
            span {
                font-family: 'Maple';
                font-size: 1.5rem;
                text-align: center;
            }
            &.all-books {
                background-color: #ecc9be;
            }
            &.single-book {
                background-color: #f2c85a;
            }
            &.series-books {
                background-color: #8ccdd7;
            }
            &.selected {
                border: 10px solid #3f8a5f;
            }
        }
    }
    .book-list {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
        &.column {
            flex-direction: column;
            gap: 46px;
        }
    }
    .bottom {
        width: 100%;
        position: absolute;
        bottom: 0px;
    }
    // 2024-02-14 | footer-text 추가
    .footer-txt {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: gray;
        padding-bottom: 0.5rem;
        font-weight: bold;
        .site {
            cursor: pointer;
        }
    }
}
// mediaQuery
@media screen and (min-width: 1367px) and (max-width: 1440px) {
    .storyland-section {
        padding-top: 120px;
        .inner {
            max-width: initial;
            width: 90%;
            padding-bottom: 200px;
            .book-list {
                width: 100%;
                justify-content: space-between;
            }
            .book-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
        }
    }
}
@media screen and (min-width: 1023px) and (max-width: 1366px) {
    .storyland-section {
        padding-top: 120px;
        .inner {
            max-width: initial;
            width: 90%;
            padding-bottom: 200px;
            .book-list {
                width: 100%;
                justify-content: space-between;
            }
            .book-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
        }
    }
}
@media screen and (min-width: 901px) and (max-width: 1022px) {
    .storyland-section {
        padding-top: 120px;
        .inner {
            max-width: initial;
            width: 90%;
            padding-bottom: 200px;
            .book-list {
                width: 100%;
                justify-content: space-between;
            }
            .book-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
        }
    }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
    .storyland-section {
        padding-top: 120px;
        .inner {
            max-width: initial;
            width: 90%;
            padding-bottom: 200px;
            .book-list {
                width: 100%;
                justify-content: space-between;
            }
            .book-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 800px) {
    .storyland-section {
        padding-top: 120px;
        .inner {
            max-width: initial;
            width: 90%;
            padding-bottom: 200px;
            .book-list {
                width: 100%;
                justify-content: space-between;
            }
            .book-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
        }
    }
}
// 2024-02-06 | 767이하 화면 미디어쿼리 조정
@media screen and (max-width: 767px) {
    .storyland-section {
        padding-top: 120px;
        .inner {
            max-width: initial;
            width: 90%;
            padding-bottom: 200px;
            .book-list {
                width: 100%;
                justify-content: space-between;
            }
            .book-list::after {
                content: '';
                width: 300px;
                height: 0;
            }
            .book-category {
                gap: 1rem;
                li {
                    width: 32%;
                    padding: 1rem;
                    justify-content: center;
                    span {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}
// 2024-02-06 | 태블릿 세로모드 미디어쿼리 추가
@media (orientation: portrait) {
    .storyland-section {
        .book-list {
            gap: 1rem;
        }
        .book-category {
            gap: 1rem;
            li {
                width: 32%;
                padding: 1rem;
                justify-content: center;
                span {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
