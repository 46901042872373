.ongoing-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        figure {
            width: 50%;
            margin-bottom: 1rem;
            animation: move 0.8s infinite alternate;
        }
        .txt-container {
            padding: 2rem 4rem;
            border-radius: 4px;
            background-color: var(--bs-white);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            p {
                font-size: 2rem;
                font-family: 'Maple';
                text-align: center;
                line-height: 1.4;
            }
            button {
                border: none;
                font-family: 'Maple';
                padding: 1rem 4rem;
                border-radius: 4px;
                font-size: 20px;
                cursor: pointer;
                background-color: var(--bs-green-light);
                color: var(--bs-white);
            }
        }
    }
    @keyframes move {
        100% {
            transform: translateY(-10px);
        }
    }
}
