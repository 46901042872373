.expired-section {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 1.5rem;
        figure {
            width: 40%;
        }
        .expired-container {
            width: 80%;
            padding: 2rem;
            background-color: var(--bs-white);
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.25rem;
            p {
                font-family: 'Maple';
                font-size: 32px;
                text-align: center;
                line-height: 1.4;
            }
            button {
                font-family: 'Maple';
                border: none;
                background: none;
                color: var(--bs-white);
                background-color: var(--bs-green-light);
                padding: 0.8rem 1.5rem;
                font-size: 20px;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }
}