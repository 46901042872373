// 2024-02-06 | landscapeModal css
.landscape-modal {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    .inner {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        figure {
            width: 50%;
        }
        .land-txt-container {
            width: 70%;
            background-color: var(--bs-white);
            border-radius: 0.5rem;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
                font-family: 'Maple';
                font-size: 2rem;
                margin-bottom: 1rem;
                color: #252525;
                text-align: center;
                line-height: 1.4;
            }
            button {
                font-family: 'Maple';
                border: none;
                background-color: var(--bs-green-light);
                color: var(--bs-white);
                padding: 0.8rem 2rem;
                border-radius: 4px;
                cursor: pointer;
                font-size: 18px;
            }
        }
    }
    @media (orientation: portrait) {
        display: block;
    }
}