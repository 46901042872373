#header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    background-color: var(--bs-white);
    border-bottom: 1px solid lightgray;
    transition-duration: 0.5s;
    opacity: 1;
    .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        .logo {
            cursor: pointer;
            width: 210px;
        }
        .user-container {
            display: flex;
            align-items: center;
            gap: 0.7rem;
            .user-id {
                font-size: 1.1rem;
                color: var(--text-dark);
                font-family: 'Maple';
                .sir {
                    font-weight: 300;
                    font-size: 1rem;
                }
            }
            button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.5rem;
                gap: 0.5rem;
                border: 1px solid #ebebeb;
                line-height: 36px;
                border-radius: 19px;
                width: 98px;
                cursor: pointer;
                figure {
                    width: 16px;
                }
            }
            // 2024.01.23 | 매직키드로 돌아가기 버튼 크기 키우기
            .back-magickid-btn {
                width: 150px;
                border-radius: 3rem;
                // 2024-02-14 | cursor:pointer 추가
                cursor: pointer;
            }
            .storyland-btn {
                padding: 0.8rem 1.25rem;
                margin-right: 0.5rem;
                border-radius: 30px;
                background-color: #00c491;
                color: var(--bs-white);
                font-size: 0.8rem;
                transition-duration: 0.3s;
                cursor: pointer;
                font-family: 'Maple';
                font-size: 1.25rem;
                &:hover {
                    background-color: #01ad7f;
                }
            }
            .library-btn {
                padding: 0.8rem 1.25rem;
                margin-right: 0.5rem;
                border-radius: 30px;
                background-color: #e6357d;
                color: var(--bs-white);
                font-size: 0.8rem;
                transition-duration: 0.3s;
                cursor: pointer;
                font-family: 'Maple';
                font-size: 1.25rem;
                &:hover {
                    background-color: #d63374;
                }
            }
        }
        .hamburger-mobile {
            display: none;
            button {
                display: flex;
                align-items: center;
                border: none;
                background-color: #f2b44a;
                gap: 0.4rem;
                padding: 0.8rem;
                border-radius: 4px;
                cursor: pointer;
                span {
                    color: var(--bs-white);
                    font-family: 'Maple';
                    font-size: 1.25rem;
                }
            }
        }
        .ham-fixed {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            background-color: var(--bs-white);
            padding: 40px 30px;
            .name-container {
                width: 100%;
                border-bottom: 1px solid #dadada;
                padding-bottom: 1rem;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                font-family: 'Maple';
                margin: 4rem 0 1rem;
                font-size: 3rem;
                color: var(--bs-green-secondary);
                span {
                    font-size: 1rem;
                    color: #000;
                }
            }
            button {
                border: none;
                background: none;
                cursor: pointer;
            }
            .close-m {
                position: relative;
                display: flex;
                justify-content: flex-end;

                button {
                    width: 3.5rem;
                    height: 3.5rem;
                    cursor: pointer;
                    font-size: 2rem;
                    background-color: #f2b44a;
                    padding: 1rem;
                    border-radius: 50%;
                }
            }
            .navi {
                padding: 2rem 0rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                li {
                    font-size: 1rem;
                    cursor: pointer;
                    font-weight: bold;
                    padding: 2rem 1rem;
                    text-align: center;
                    &.storyland {
                        background-color: #00c491;
                        font-family: 'Maple';
                        font-size: 1.8rem;
                        color: var(--bs-white);
                        border-radius: 8px;
                        font-weight: lighter;
                    }
                    &.my-library {
                        background-color: #e6357d;
                        font-family: 'Maple';
                        font-size: 1.8rem;
                        color: var(--bs-white);
                        border-radius: 8px;
                        font-weight: lighter;
                    }
                    &.back-magic-kid {
                        background-color: #1f3a79;
                        font-family: 'Maple';
                        font-size: 1.8rem;
                        color: var(--bs-white);
                        border-radius: 8px;
                        font-weight: lighter;
                    }
                }
            }
        }
    }
    // .login-container {
    //     display: flex;
    //     align-items: center;
    //     font-size: 0.8rem;
    //     cursor: pointer;
    //     .util {
    //         border: 2px solid #00c491;
    //         padding: 0.8rem;
    //         border-radius: 30px;
    //         color: #e7357c;
    //         font-weight: 900;
    //         span:first-child::after {
    //             content: '|';
    //             display: inline-block;
    //             padding: 0 10px;
    //             color: #fac03d;
    //         }
    //     }
    // }
}
#header.hovered {
    opacity: 0;
    &:hover {
        opacity: 1;
    }
}
// mediaQuery
@media screen and (min-width: 1023px) and (max-width: 1440px) {
    #header {
        .inner {
            width: 90%;
            .storyland-btn {
                padding: 1rem 2rem;
                font-size: 20px;
            }
            .library-btn {
                padding: 1rem 2rem;
                font-size: 20px;
            }
        }
        .user-container .back-magickid-btn {
            width: 190px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 1022px) {
    #header {
        .inner {
            width: 90%;
            .user-container {
                display: none;
            }
            .storyland-btn {
                padding: 1rem 2rem;
                font-size: 20px;
            }
            .library-btn {
                padding: 1rem 2rem;
                font-size: 20px;
            }
            .hamburger-mobile {
                display: block;
            }
        }
        .user-container .back-magickid-btn {
            width: 190px;
            cursor: pointer;
        }
    }
}
@media (orientation: portrait) {
    #header {
        .inner {
            width: 90%;
            .user-container {
                display: none;
            }
            .storyland-btn {
                padding: 1rem 2rem;
                font-size: 20px;
            }
            .library-btn {
                padding: 1rem 2rem;
                font-size: 20px;
            }
            .hamburger-mobile {
                display: block;
            }
        }
    }
}
